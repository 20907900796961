import './style/trackerList.scss';

import React, {MouseEventHandler, useMemo} from 'react';
import Tracker from '../../models/trackerModel';
import {AwesomeIcon, Loader} from '@dvrd/dvr-controls';
import classNames from 'classnames';

interface Props {
    onDeleteTracker: (tracker: Tracker) => MouseEventHandler;
    onClickTracker: (tracker: Tracker) => MouseEventHandler;
    onClickAdd: MouseEventHandler;
    onChangePage: (nextPage: number) => MouseEventHandler;
    limit: number;
    page: number;
    trackers: Array<Tracker>;
    loading: boolean;
}

export default function TrackerList(props: Props) {
    const {onDeleteTracker, onClickTracker, onChangePage, onClickAdd, limit, page, trackers, loading} = props;
    const [from, to] = useMemo(() => {
        const from = page * limit + 1;
        const to = page * limit + limit;
        return [from, to];
    }, [page, limit]);

    function renderTracker(tracker: Tracker, index: number) {
        return (
            <div key={index} className='default-table-row row' onClick={onClickTracker(tracker)}>
                <label>{tracker.zipCode}</label>
                <label>{tracker.fullHouseNumber}</label>
                <label>{tracker.email}</label>
                <label>{tracker.notifyTime}</label>
                <label>{tracker.notificationGarbageType}</label>
                <AwesomeIcon name='trash-alt' className='btn-delete'
                             onClick={onDeleteTracker(tracker)}/>
            </div>
        );
    }

    return (
        <div className='default-page tracker-list'>
            <Loader active={loading}/>
            <div className='default-table-row head row'>
                <label>POSTCODE</label>
                <label>HUISNUMMER</label>
                <label>E-MAILADRES</label>
                <label>MELDING OM</label>
                <label>MELDING VOOR</label>
                <AwesomeIcon name='plus-circle' onClick={onClickAdd} className='btn-add'/>
            </div>
            {trackers.map(renderTracker)}
            <div className='pagination-container'>
                <label className='count-label'>{from} t/m {to}</label>
                <AwesomeIcon name='chevron-left' onClick={onChangePage(page - 1)}
                             className={classNames('pager', from === 1 && 'disabled')}/>
                <AwesomeIcon name='chevron-right' onClick={onChangePage(page + 1)} className='pager'/>
            </div>
        </div>
    );
}

TrackerList.displayName = 'TrackerList';