import React, {PropsWithChildren, useContext} from 'react';
import {AppContext} from "../context/appContext";
import {Navigate} from 'react-router-dom';

interface Props {
    authenticated?: boolean;
    children: any;
}

export default function AppRoute(props: PropsWithChildren<Props>) {
    if (props.authenticated) {
        const context = useContext(AppContext);
        if (!context.userContext.user?.id.length) return <Navigate to='/inloggen' replace/>
    }
    return props.children;
}