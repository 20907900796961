import {showDialog, showSnackbar} from '@dvrd/dvr-controls';
import {ResponseData} from '@dvrd/fetch';
import React, {useEffect, useRef, useState} from 'react';
import Tracker from '../../models/trackerModel';
import {ActivateHandle} from '../../util/interfaces';
import TrackerDataController from './data/trackerDataController';
import TrackerList from './trackerList';

const LIMIT = 10;

export default function TrackerListController() {
    const [trackers, setTrackers] = useState<Array<Tracker>>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const dataRef = useRef<ActivateHandle>(null);

    function onChangePage(nextPage: number) {
        return function () {
            setPage(nextPage);
        };
    }

    function onClickTracker(tracker: Tracker) {
        return function () {
            dataRef.current?.activate(tracker);
        };
    }

    function onClickAdd() {
        dataRef.current?.activate();
    }

    function onConfirmDeleteTracker(tracker: Tracker) {
        return function (evt: React.MouseEvent) {
            evt.stopPropagation();
            showDialog('Weet je zeker dat je deze tracker wilt verwijderen?', 'Tracker verwijderen',
                [{label: 'Ja', onClick: onDeleteTracker(tracker), primary: false}, 'Nee']);
        };
    }

    function onDeleteTracker(tracker: Tracker) {
        return function () {
            setLoading(true);
            tracker.delete((tracker: Tracker, success: boolean, data: ResponseData) => {
                setLoading(false);
                if (success) {
                    setTrackers(trackers.filter((track: Tracker) => track.id !== tracker.id));
                    showSnackbar('De tracker is verwijderd');
                } else
                    showDialog(data.message ?? 'Het verwijderen van de tracker is niet gelukt.', 'Verwijderen mislukt');
            });
        };
    }

    function loadTrackers() {
        setLoading(true);
        Tracker.getAll(LIMIT, page, (trackers: Array<Tracker>, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) setTrackers(trackers);
            else showDialog(data.message ?? 'Het laden is niet gelukt.', 'Laden mislukt');
        });
    }

    useEffect(() => {
        loadTrackers();
    }, [page]);

    return (
        <>
            <TrackerList onDeleteTracker={onConfirmDeleteTracker} onClickTracker={onClickTracker}
                         onClickAdd={onClickAdd} onChangePage={onChangePage} limit={LIMIT} page={page}
                         trackers={trackers} loading={loading}/>
            <TrackerDataController ref={dataRef} onSuccess={loadTrackers}/>
        </>
    );
}

TrackerListController.displayName = 'TrackerListController';