import './style/loginPage.scss';

import {ChangeFunction, DvrdInput, ErrorType, preventDefault, DVRDPasswordInput, Loader} from '@dvrd/dvr-controls';
import classNames from 'classnames';
import React, {KeyboardEventHandler, MouseEventHandler} from 'react';

interface Props {
    onChangeEmail: ChangeFunction<string>;
    onChangePassword: ChangeFunction<string>;
    onSubmit: MouseEventHandler & KeyboardEventHandler;
    email: string;
    password: string;
    error: ErrorType;
    loading: boolean;
}

export default function LoginPage(props: Props) {
    const {onChangeEmail, onChangePassword, onSubmit, email, password, error, loading} = props;

    return (
        <div className='login-page'>
            <form className={classNames('login-form', !!error && 'error')} onSubmit={preventDefault}>
                <Loader active={loading}/>
                <label className='form-title'>Inloggen</label>
                <DvrdInput value={email} onChange={onChangeEmail} label='E-mailadres' placeholder='E-mailadres'
                           type='email' inputProps={{autoComplete: 'username'}} onEnter={onSubmit}/>
                <DVRDPasswordInput value={password} onChange={onChangePassword} label='Wachtwoord'
                                   placeholder='Wachtwoord' inputProps={{autoComplete: 'current-password'}}
                                   onEnter={onSubmit}/>
                <label className='error-label'>{error}</label>
            </form>
        </div>
    );
}

LoginPage.displayName = 'LoginPage';