import {ErrorType} from '@dvrd/dvr-controls';
import React, {useContext, useState} from 'react';
import {Navigate} from 'react-router-dom';
import User from '../../../models/userModel';
import {TRACKERS_ROUTE} from '../../../util/constants';
import {login} from '../../../util/requestSender';
import {AppContext} from '../../context/appContext';
import LoginPage from './loginPage';
import { ResponseData } from '@dvrd/fetch';

export default function LoginController() {
    const context = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<ErrorType>(null);
    const [loading, setLoading] = useState<boolean>(false);
    if (!!context.userContext.user) return <Navigate to={TRACKERS_ROUTE}/>;

    function onChangeEmail(value: string) {
        setEmail(value);
    }

    function onChangePassword(value: string) {
        setPassword(value);
    }

    function onSubmit() {
        if (!validateValues()) return;
        setLoading(true);
        login({
            data: {username: email, password}, callback: (data: ResponseData) => {
                setLoading(false);
                if (data.success) context.onChangeContext({user: new User(data.user)});
                else setError('Ongeldige combinatie van e-mail en wachtwoord');
            }
        });
    }

    function validateValues(): boolean {
        if (!email) {
            setError('Vul je e-mailadres in');
            return false;
        }
        if (!password) {
            setError('Vul het wachtwoord in');
            return false;
        }
        return true;
    }

    return (
        <LoginPage onChangeEmail={onChangeEmail} onChangePassword={onChangePassword} onSubmit={onSubmit} email={email}
                   password={password} error={error} loading={loading}/>
    );
}

LoginController.displayName = 'LoginController';