import './style/trackerData.scss';

import {
    capitalize, ChangeFunction, ChangeKeyFunction, Checkbox, CloseButton, DvrdButton, DvrdInput, DVRDNumberInput,
    preventDefault, WithBackground
} from '@dvrd/dvr-controls';
import React, {MouseEventHandler, useMemo} from 'react';
import Tracker, {TrackerFields} from '../../../models/trackerModel';
import {DataMode, GarbageType} from '../../../util/interfaces';
import {translateGarbageType} from '../../../util/utils';

interface Props {
    onChange: ChangeKeyFunction<keyof TrackerFields, any>;
    onSelectGarbageType: (garbageType: GarbageType) => ChangeFunction<boolean>;
    onSubmit: MouseEventHandler;
    onClose: MouseEventHandler;
    tracker: Tracker;
    active: boolean;
    loading: boolean;
    mode: DataMode;
}

export default function TrackerData(props: Props) {
    const {onChange, onSubmit, onClose, tracker, active, loading, mode, onSelectGarbageType} = props;
    const action = useMemo(() => {
        return mode === DataMode.NEW ? 'toevoegen' : 'bijwerken';
    }, [mode]);

    function renderForm() {
        return (
            <form className='body-container' onSubmit={preventDefault}>
                <div className='row'>
                    <DvrdInput value={tracker.zipCode} onChange={onChange('zip_code')} label='Postcode'/>
                    <DvrdInput value={tracker.houseNumber} onChange={onChange('house_number')} label='Huisnummer'/>
                    <DvrdInput value={tracker.houseNumberExtra ?? ''} onChange={onChange('house_number_extra')}
                               label='Toevoeging'/>
                </div>
                <DvrdInput value={tracker.email} onChange={onChange('email')} label='E-mailadres'/>
                <label>Melding om</label>
                <div className='row'>
                    <DVRDNumberInput value={tracker.notifyHour} onChange={onChange('notify_hour')} wholeNumbers
                                     disableSymbols/>
                    <span className='time-sep'>:</span>
                    <DVRDNumberInput value={tracker.notifyMinute} onChange={onChange('notify_minute')} wholeNumbers
                                     disableSymbols/>
                </div>
                <div className='garbage-types'>
                    {renderGarbageType(GarbageType.REST)}
                    {renderGarbageType(GarbageType.GFT)}
                    {renderGarbageType(GarbageType.PMD)}
                    {renderGarbageType(GarbageType.PAPER)}
                    {renderGarbageType(GarbageType.BRANCHES)}
                    {renderGarbageType(GarbageType.CHRISTMAS_TREE)}
                </div>
            </form>
        );
    }

    function renderGarbageType(garbageType: GarbageType) {
        const checked = tracker.garbageTypes.includes(garbageType);
        const label = translateGarbageType(garbageType);
        return (
            <Checkbox checked={checked} onCheck={onSelectGarbageType(garbageType)} label={label}/>
        );
    }

    return (
        <WithBackground active={active} onClose={onClose}>
            <div className='default-popup tracker-data'>
                <div className='header-container'>
                    <label className='header-title'>Tracker {action}</label>
                    <CloseButton onClick={onClose}/>
                </div>
                {renderForm()}
                <div className='footer-container btn-2'>
                    <DvrdButton onClick={onClose} label='Sluiten' secondary disabled={loading}/>
                    <DvrdButton onClick={onSubmit} label={capitalize(action)} disabled={loading}/>
                </div>
            </div>
        </WithBackground>
    );
}

TrackerData.displayName = 'TrackerData';