import {showDialog, showSnackbar} from '@dvrd/dvr-controls';
import {ResponseData} from '@dvrd/fetch';
import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from 'react';
import Tracker, {TrackerFields} from '../../../models/trackerModel';
import {ActivateHandle, DataMode, GarbageType} from '../../../util/interfaces';
import TrackerData from './trackerData';

interface Props {
    onSuccess: VoidFunction;
}

function TrackerDataController(props: Props, ref: ForwardedRef<ActivateHandle<Tracker | null>>) {
    const {onSuccess} = props;
    const [tracker, setTracker] = useState<Tracker>(new Tracker());
    const [mode, setMode] = useState<DataMode>(DataMode.NEW);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);

    function onChange(key: keyof TrackerFields) {
        return function (value: any) {
            setTracker(tracker.clone().setField(key, value));
        };
    }

    function onSelectGarbageType(garbageType: GarbageType) {
        return function () {
            let types: Array<GarbageType>;
            if (tracker.garbageTypes.includes(garbageType))
                types = tracker.garbageTypes.filter((typ: GarbageType) => typ !== garbageType);
            else types = tracker.garbageTypes.concat(garbageType);
            onChange('garbage_types')(types);
        };
    }

    function onClose() {
        setActive(false);
        setTracker(new Tracker());
        setMode(DataMode.NEW);
        setLoading(false);
    }

    function onActivate(tracker?: Tracker) {
        setTracker(tracker ?? new Tracker());
        setMode(!!tracker ? DataMode.EDIT : DataMode.NEW);
        setActive(true);
    }

    function onSubmit() {
        if (mode === DataMode.NEW) onSave();
        else onUpdate();
    }

    function onSave() {
        tracker.create((tracker: Tracker, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onClose();
                onSuccess();
                showSnackbar('De tracker is toegevoegd');
            } else
                showDialog(data.message ?? 'Het toevoegen is niet gelukt.', 'Toevoegen mislukt');
        });
    }

    function onUpdate() {
        tracker.update((tracker: Tracker, success: boolean, data: ResponseData) => {
            setLoading(false);
            if (success) {
                onClose();
                onSuccess();
                showSnackbar('De tracker is bijgewerkt');
            } else
                showDialog(data.message ?? 'Het bijwerken is niet gelukt.', 'Bijwerken mislukt');
        });
    }

    useImperativeHandle(ref, () => ({activate: onActivate}));

    return (
        <TrackerData onChange={onChange} onSelectGarbageType={onSelectGarbageType} onSubmit={onSubmit} onClose={onClose}
                     tracker={tracker} active={active} loading={loading} mode={mode}/>
    );
}

TrackerDataController.displayName = 'TrackerDataController';

export default forwardRef<ActivateHandle, Props>(TrackerDataController);